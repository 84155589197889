import request from './request'
let baseUrl = 'http://47.104.237.15:9901/'

/*
 *@description:  风险评估界面 某地区站点统计
 *@author: hanyan
 *@date: 2024-09-25 13:58:42
 *@version: V1.0.5
*/
export const getAllCountZd = (city_level) => {
    return request({
        url: `${baseUrl}API_V1.0/collection/site_transformer_count/${city_level}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description:  风险评估界面 某地区被检测设备统计
 *@author: hanyan
 *@date: 2024-09-25 14:00:18
 *@version: V1.0.5
*/
export const getAllCountDevice = (city_level) => {
    return request({
        url: `${baseUrl}API_V1.0/collection/test_suffer_equipment_count/${city_level}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};

/*
 *@description:  风险评估界面 某地区近一个月内重过载站点数量
 *@author: hanyan
 *@date: 2024-09-25 14:00:34
 *@version: V1.0.5
*/
export const getGZZCountZd = (city_level) => {
    return request({
        url: `${baseUrl}API_V1.0/collection/analysis_month_average01_1/${city_level}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};
/*
 *@description:  风险评估界面 某地区近一个月内重过载设备信息
 *@author: hanyan
 *@date: 2024-09-25 14:00:58
 *@version: V1.0.5
*/
export const getGZZCountDevice = (city_level) => {
    return request({
        url: `${baseUrl}API_V1.0/collection/analysis_month_average01_1_list/${city_level}`,
        method: 'get',
        headers: {
            'token': sessionStorage.getItem('token')
        }
    });
};


<template>
  <div class="analysis-content1">
    <div class="content-analysis1">
      <div class="left-content1">
        <div class="byq-info">
          <div class="rectangle margin-t">
            <div class="triangle-top-right coloer"></div>
            <div class="d-flex titleS">广东省各个站点共</div>
            <div class="d-flex titleS">
              <span class="numbers"> {{ zdCount }}</span
              ><span class="texts">个</span>
            </div>
          </div>
          <div>
            <div class="rectangle1 margin-t">
              <div class="triangle-top-right coloered"></div>
              <div class="d-flex">
                <div class="titleS">过载站点</div>
                <div class="titleS">
                  <span class="numbers"> {{heavy}}</span><span class="texts">个</span>
                </div>
              </div>
            </div>
            <div class="rectangle1 margin-t">
              <div class="triangle-top-right coloerllow"></div>
              <div class="d-flex">
                <div class="titleS">重载站点</div>
                <div class="titleS">
                  <span class="numbers"> {{overload}}</span><span class="texts">个</span>
                </div>
              </div>
            </div>
          </div>

          <div class="rectangle">
            <div class="triangle-top-right coloer"></div>
            <div class="d-flex" v-for="item in devicesList" :key="item[0]">
              <div class="titleS">{{ item[0] }}</div>
              <div class="titleS">
                <span class="numbers"> {{ item[1] }}</span
                ><span class="texts">个</span>
              </div>
            </div>
          </div>
          <div class="rectangle">
            <div class="triangle-top-right coloer"></div>
            <div class="d-flex titleS">当前负荷</div>
            <div class="d-flex titleS">
              <span class="numbers"> 123</span><span class="texts">万千瓦</span>
            </div>
          </div>
        </div>
        <div class="status-cgq"></div>
        <div class="table-list">
          <div class="d-flex">
            <span class="title">全省重载/过载变压器列表</span>
          </div>
          <div class="scroll-table">
            <div class="row" v-for="(item, index) in dataList" :key="index">
              <div class="img"><el-image :src="item.imgSrc" /></div>
              <div class="right">
                <div class="d-flex text">{{ item.equipment_name }}</div>
                <div class="d-flex"><el-button @click="clickEvent(item.test_equipment_id)">{{item.zgz}}</el-button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center-content1">
        <div class="wd">
          <div class="mapS"><mapx /></div>
        </div>
        <div class="jf">
          <div class="d-flex text-1">近一周各地级市预计增加最大负荷</div>
          <div class="chart-1">
            <hx :cdata="cdata3" :id="'hx_3'" />
          </div>
        </div>
      </div>
      <div class="right-content1">
        <div class="wd">
          <div class="mapX"><mapS /></div>
        </div>
        <div class="jf">
          <div class="chart-group">
            <div class="d-flex title">重过载情况对比</div>
            <div class="d-flex subtitle">各地重/过载情况对比</div>
            <div class="chart-1">
              <hx :cdata="cdata1" :id="'hx_1'" />
            </div>
            <div class="d-flex subtitle">当前省重/过载情况对比</div>
            <div class="chart-2">
              <hx :cdata="cdata2" :id="'hx_2'" />
            </div>
          </div>
          <div class="youhua">
            <div class="d-flex title">优化工作建议</div>
            <div class="d-flex">
              <span class="text-1">变压器：</span
              ><span class="text-2">28台过载</span
              ><span class="text-3">13台重载</span>
            </div>
            <div class="d-flex margin-1">
              <span class="text-4">> 及时提醒客户；</span>
            </div>
            <div class="d-flex margin-1">
              <span class="text-4">> 协助制定解决方案；</span>
            </div>
            <div class="d-flex margin-1">
              <span class="text-4">> 可推荐升级或调整负荷。</span>
            </div>
            <div class="d-flex">
              <span class="text-1">开关柜：</span
              ><span class="text-2">10台过载</span
              ><span class="text-3">43台重载</span>
            </div>
            <div class="d-flex margin-1">
              <span class="text-4">> 检查更换；</span>
            </div>
            <div class="d-flex margin-1">
              <span class="text-4">> 重载台区改造。</span>
            </div>
            <div class="d-flex">
              <span class="text-1">线路：</span
              ><span class="text-2">12台过载</span
              ><span class="text-3">43台重载</span>
            </div>
            <div class="d-flex margin-1">
              <span class="text-4">> 调整运行方式（倒负荷）</span>
            </div>
            <div class="d-flex margin-1">
              <span class="text-4">> 实时监控，及时进行负荷转移</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mapx from "./map.vue";
import mapS from "./map1.vue";
import hx from "./hxecharts.vue"; // 导入prpd组件
import {
  getAllCountZd,
  getAllCountDevice,
  getGZZCountZd,
  getGZZCountDevice,
} from "@/api/tupuIndex";
export default {
  props: {},
  components: { hx, mapx, mapS },
  data() {
    return {
      zdCount: 0,
      devicesList: [
        ["开关柜", 0],
        ["变压器", 0],
        ["电缆", 0],
      ],
      heavy: 0,
      overload: 0,
      dataList: [
        {
          test_equipment_id: 10001,
          equipment_name: "变压器001",
          tytime: "2009-12-08",
          region: "xxxxxxxx",
          zgz: "重载",
          imgSrc: require("../image/byq.png"),
        },
        {
          test_equipment_id: 10002,
          equipment_name: "变压器002",
          tytime: "2009-12-08",
          region: "xxxxxxxx",
          zgz: "过载",
          imgSrc: require("../image/byq1.png"),
        }
        ],
      cdata1: {
        backgroundColor: "#01369833",
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["过载台区", "重载台区"],
          align: "right",
          right: 10,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["深圳", "珠海", "汕头", "湛江", "惠州"],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "总数",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "过载台区",
            type: "bar",
            data: [20, 12, 31, 34, 31],
          },
          {
            name: "重载台区",
            type: "bar",
            data: [10, 20, 5, 9, 3],
          },
        ],
      },
      cdata2: {
        backgroundColor: "transparent",
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["过载台区", "重载台区"],
          align: "right",
          right: 10,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["龙岗区", "坪山区", "宝安区", "光明区", "罗湖区"],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "总数",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "过载台区",
            type: "bar",
            data: [20, 12, 31, 34, 31],
          },
          {
            name: "重载台区",
            type: "bar",
            data: [10, 20, 5, 9, 3],
          },
        ],
      },
      cdata3: {
        backgroundColor: "transparent",
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: ["负荷"],
          align: "right",
          right: 10,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["深圳", "珠海", "汕头", "湛江", "惠州"],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "总量（万千瓦）",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            name: "负荷",
            type: "bar",
            data: [20, 12, 31, 34, 31],
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    setData() {
      // 站点统计
      getAllCountZd("广东省").then((res) => {
        if (res.code == 200) {
          this.zdCount = res.count;
        } else {
          this.$message.error(res.error);
        }
      });
      // 设备数目统计
      getAllCountDevice("广东省").then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.devicesList = res.count
        } else {
          this.$message.error(res.error);
        }
      });
      // 过重载站点统计
      getGZZCountZd("广东省").then((res) => {
        if (res.code == 200) {
          this.heavy = res.heavy
          this.overload = res.overload
        } else {
          this.$message.error(res.error);
        }
      });
      // 过重载设备统计
      getGZZCountDevice("广东省").then((res) => {
        console.log(res);
        if (res.code == 200) {
          // this.devicesList = res.count
          console.log(res)
          this.dataList = []
          if(res.count_heavy.length>0 || res.count_overload.length>0){
            res.count_heavy.forEach(item => {
              this.dataList.push({
                test_equipment_id: item.test_equipment_id,
                equipment_name: item.equipment_name,
                tytime: "2009-12-08",
                imgSrc: require("../image/byq1.png"),
                zgz: "重载"
              })
            })
            res.count_overload.forEach(item => {
              this.dataList.push({
                test_equipment_id: item.test_equipment_id,
                equipment_name: item.equipment_name,
                tytime: "2009-12-08",
                imgSrc: require("../image/byq1.png"),
                zgz: "过载"
              })
            })
          }
        } else {
          this.$message.error(res.error);
        }
      });
    },
  },
  mounted() {
    this.setData();
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
}
.margin-t {
  padding-top: 30px;
}
.margin-1 {
  margin-top: 10px;
}
.text-1 {
  color: #fff;
  font-size: 18px;
}
.text-2 {
  color: #ff1515;
  font-size: 18px;
}
.text-3 {
  color: #fbee77;
  font-size: 18px;
}
.text-4 {
  color: #c9c1c9;
  font-size: 15px;
}
.display-align {
  align-items: center;
}
.display-justify {
  justify-content: space-between;
}
/* Your styles here */
.analysis-content1 {
  margin: 0 10px;
  height: calc(100vh - 160px);
  overflow-y: scroll;
  color: rgb(184, 171, 171);
  .content-analysis1 {
    width: 95%;
    display: flex;
    height: calc(100vh - 180px);
    margin: 0px auto;

    .left-content1 {
      flex: 2;
      height: 100%;
      display: grid;
      grid-template-rows: 3fr 2fr 1fr;
      .byq-info {
        margin: 10px 10px 10px 10px;
        display: flex;
        flex-wrap: wrap;
        background-image: url("../image/border.png");
        background-size: 100%;
        background-repeat: no-repeat; /* 防止背景图像重复 */
        background-position: center center; /* 背景图像居中对齐 */
        .titleS {
          position: relative;
          top: 0px;
          line-height: 30px;
          color: #fff;
          font-size: 20px;
        }
        .numbers {
          color: rgb(255, 255, 8);
          font-size: 30px;
          margin-top: 10px;
        }
        .texts {
          font-size: 20px;
          line-height: 20px;
          margin-top: 20px;
          margin-left: 10px;
        }
        .rectangle {
          width: 180px;
          height: 40%;
          background-color: #040b7433; /* 浅灰色 */
          border: 1px solid #4ac6f7;
          position: relative; /* 设置相对定位，以便三角形可以相对于它定位 */
          margin: 10px 0px 0px 40px;
        }
        .rectangle1 {
          width: 180px;
          height: 40%;
          background-color: #040b7433; /* 浅灰色 */
          border: 1px solid #4ac6f7;
          position: relative; /* 设置相对定位，以便三角形可以相对于它定位 */
          margin: 5px 0px 0px 40px;
        }

        .triangle-top-right {
          width: 0;
          height: 0;
          border-left: 30px solid transparent; /* 左侧边框透明 */
          border-right: 0 solid transparent; /* 右侧边框透明，这里宽度设置为0，因为三角形不需要右侧边框 */
          rotate: (270deg);
          position: absolute; /* 绝对定位 */
          top: 0; /* 紧贴矩形的顶部 */
          right: 0; /* 紧贴矩形的右侧 */
        }
        .coloer {
          border-bottom: 30px solid #1cff7b; /* 底部边框颜色，即三角形的颜色 */
        }
        .coloered {
          border-bottom: 30px solid #ff1c1c; /* 底部边框颜色，即三角形的颜色 */
        }
        .coloerllow {
          border-bottom: 30px solid #e8ff16; /* 底部边框颜色，即三角形的颜色 */
        }
      }
      .status-cgq {
        border: 1px solid #193c58;
        margin: 0px 5px;
        background-image: url("../image/tu.png");
        background-color: rgba(2, 88, 64, 0.226); /* 浅灰色 */
        background-size: 78%;
        background-repeat: no-repeat; /* 防止背景图像重复 */
        background-position: center center; /* 背景图像居中对齐 */
      }
      .table-list {
        border: 2px solid #0f80b5;
        margin: 5px;
        background-color: #08122765;
        .title {
          font-size: 18px;
          color: #fff200;
        }
        .scroll-table {
          display: flex;
          flex-wrap: wrap;
          overflow-y: auto; /* 允许垂直滚动 */
          height: 200px; /* 或者你需要的任何高度 */
        }

        .row {
          display: flex;
          // flex-wrap: wrap;
          width: 180px;
          align-items: center;
          border-bottom: 1px solid #eee; /* 可选，添加行分隔线 */
          border-bottom: 1px solid rgb(64, 159, 214);
          color: rgba(248, 248, 248, 0.8);
          font-size: 14px;
          .img {
            width: 60px;
            height: 57px;
          }
          .right {
            .text {
              margin-top: 10px;
              font-size: 14px;
            }
            /deep/ .el-button {
              display: inline-block;
              line-height: 0;
              height: 18px;
              white-space: nowrap;
              color: rgb(255, 253, 136);
              font-size: 14px;
              background-color: #096d0e73;
              cursor: pointer;
              border: 2px solid #a30202;
            }
          }
        }
      }
    }
    .center-content1 {
      flex: 3;
      display: grid;
      grid-template-rows: 4fr 2fr;
      .wd {
        margin: 10px 10px 10px 10px;
        padding: 20px;
        border: 2px solid #0f80b5;
        .mapS {
          width: 100%;
          height: 100%;
        }
      }
      .jf {
        margin: 10px 10px 10px 10px;
        padding: 20px;
        border: 2px solid #0f80b5;
        .chart-1 {
          height: 40%;
        }
      }
    }
    .right-content1 {
      flex: 2;
      height: 100%;
      display: grid;
      grid-template-rows: 2.5fr 3fr;
      .wd {
        margin: 10px 10px 10px 10px;
        padding: 30px;
        border: 2px solid #0f80b5;
        .mapX {
          width: 100%;
          height: 100%;
        }
      }
      .jf {
        display: flex;
        .chart-group {
          border: 2px solid #0f80b5;
          margin: 5px;
          flex: 1;
          .title {
            color: #3bffc4;
            font-size: 20px;
            margin: 10px 0 20px 20px;
          }
          .subtitle {
            font-size: 16px;
            color: #fff;
          }
          .chart-1 {
            height: 40%;
          }
          .chart-2 {
            height: 40%;
          }
        }
        .youhua {
          border: 2px solid #0f80b5;
          margin: 5px;
          flex: 1;
        }
        .title {
          color: #3bffc4;
          font-size: 20px;
          margin: 10px 0 20px 20px;
        }
      }
    }
  }
}
</style>


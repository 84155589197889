<template>
  <div class="map-content">
    <div class="map-cx">
      <div class="left">
        <div class="top">
          <div class="guozai">
            <div class="circle red d-flex"></div>
            <div class="d-flex redfont">过载</div>
            <div class="d-flex fontS">负载率为</div>
            <div class="d-flex fontS">
              <span class="redfont">100%</span>以上
            </div>
          </div>
          <div class="zhongzai">
            <div class="circle yellow d-flex"></div>
            <div class="d-flex yellowfont">重载</div>
            <div class="d-flex fontS">负载率为</div>
            <div class="d-flex fontS">
              <span class="yellowfont">80%~100%</span>以上
            </div>
          </div>
        </div>
        <div class="bottom">
          <Chart :cdata="cdata" />
        </div>
      </div>
      <div
        class="center"
        ref="orgOptions"
        :style="{ width: '100px', height: '100%' }"
      ></div>
    </div>

    <div class="right">
      <el-select v-model="value" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { shanxi } from "@/api/index";
import Chart from "./hxecharts.vue";
export default {
  data() {
    return {
      options: [
        {
          value: "1",
          label: "广东省",
        }
      ],
      value: "",
      cdata: {
        title: {
          text: "",
          subtext: "",
          left: "center",
          textStyle: {
            color: "#fff",
            fontSize: 18,
          },
        },

        backgroundColor: "transparent",
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}:({d}%)",
        },
        series: [
          {
            name: "重过载比率",
            type: "pie",
            radius: ["30%", "100%"],
            color: ["yellow", "orange", "red", "#3dc6a8"],
            label: {
              normal: {
                position: "inner",
                formatter: "{b}\n{d}%",
              },
            },
            data: [
              {
                value: 74.86,
                name: "正常占比",
              },
              {
                value: 8.38,
                name: "过载占比",
              },
              {
                value: 16.76,
                name: "重载占比",
              },
            ],
          },
        ],
      },
    };
  },
  components: {
    Chart,
  },
  mounted() {
    this.setData();
  },
  methods: {
    initMap(option) {
      let that = this;
      if (that.myChart) {
        that.myChart.clear();
        that.myChart.dispose();
        that.myChart = null;
      }
      //   window.removeEventListener('resize', this.resizeHandler)
      that.myChart = that.$echarts.init(that.$refs.orgOptions);
      let myChart = that.myChart;
      //   let option;
      shanxi().then((svg) => {
        that.$echarts.registerMap("shenzhen", svg);
        myChart.setOption(option, true);
        // myChart.on("click", function (params) {
        //   // params.name 即为点击的地区名称
        //   let seriesData = that.seriesData.filter(
        //     (item) => item.name == params.name
        //   );
        //   // return
        //   if (seriesData.length > 0) {
        //     that.$router.push({
        //       path: "/equipment/equipment",
        //       query: {
        //         stationName: seriesData[0].name,
        //         stationId: seriesData[0].id,
        //       },
        //     });
        //     sessionStorage.setItem("stationId", seriesData[0].id);
        //   }
        // });
      });
    },
    setData() {
      // var data = [
      //   { name: "西安市", value: 199 },
      //   { name: "铜川市", value: 39 },
      //   { name: "宝鸡市", value: 152 },
      //   { name: "咸阳市", value: 299 },
      //   { name: "渭南市", value: 89 },
      //   { name: "延安市", value: 52 },
      //   { name: "汉中市", value: 9 },
      //   { name: "榆林市", value: 352 },
      //   { name: "安康市", value: 99 },
      //   { name: "商洛市", value: 39 },
      // ];
      // var geoCoordMap = {
      //   西安市: [108.948, 34.2632],
      //   铜川市: [108.93, 34.9],
      //   宝鸡市: [107.13, 34.37],
      //   咸阳市: [108.7, 34.33],
      //   渭南市: [109.5, 34.5],
      //   延安市: [109.48, 36.6],
      //   汉中市: [107.02, 33.07],
      //   榆林市: [109.73, 38.28],
      //   安康市: [109.02, 32.68],
      //   商洛市: [109.93, 33.87],
      // };
      var data = [{ name: "深圳市", value: 39 }];
      var geoCoordMap = {
        深圳市: [114.085947, 22.547],
      };
      let option;
      var max = 480,
        min = 9; // todo
      var maxSize4Pin = 100,
        minSize4Pin = 20;
      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
            });
          }
        }
        return res;
      };
      option = {
        title: {
          text: "“风险预警” - 广东省",
          subtext: "",
          x: "center",
          textStyle: {
            color: "#ccc",
          },
        },
        grid: {
          left: "0%",
        },
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (typeof params.value[2] == "undefined") {
              return params.name + " : " + params.value;
            } else {
              return params.name + " : " + params.value;
            }
          },
        },
        legend: {
          orient: "vertical",
          y: "top",
          x: "right",
          data: ["预警地图"],
          textStyle: {
            color: "#fff",
          },
        },
        visualMap: {
          show: false,
          min: 0,
          max: 500,
          left: "left",
          top: "bottom",
          text: ["高", "低"], // 文本，默认为数值文本
          calculable: true,
          seriesIndex: [1],
          inRange: {
            color: ["#3B5077", "#031525"], // 蓝黑
            color: ["#ffc0cb", "#800080"], // 红紫
            color: ["#3C3B3F", "#605C3C"], // 黑绿
            color: ["#0f0c29", "#302b63", "#24243e"], // 黑紫黑
            color: ["#23074d", "#cc5333"], // 紫红
            color: ["#00467F", "#A5CC82"], // 蓝绿
            color: ["#1488CC", "#2B32B2"], // 浅蓝
            // color: ['#00467F', '#A5CC82'] // 蓝绿
            // color: ['#00467F', '#A5CC82'] // 蓝绿
            // color: ['#00467F', '#A5CC82'] // 蓝绿
            // color: ['#00467F', '#A5CC82'] // 蓝绿
          },
        },
        // toolbox: {
        //     show: true,
        //     orient: 'vertical',
        //     left: 'right',
        //     top: 'center',
        //     feature: {
        //             dataView: {readOnly: false},
        //             restore: {},
        //             saveAsImage: {}
        //             }
        // },
        geo: {
          show: true,
          map: "shenzhen",
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          roam: true,
          itemStyle: {
            normal: {
              // areaColor: "#",
              // borderColor: "#3B5077",
              areaColor: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#29779D", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#103665", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
              borderColor: "#14F0E3", //边框线
              borderWidth: 1,
            },
            emphasis: {
              areaColor: "#2B91B7",
            },
          },
        },
        series: [
          {
            name: "预警地图",
            type: "scatter",
            coordinateSystem: "geo",
            data: convertData(data),
            symbolSize: function (val) {
              return val[2] / 10;
            },
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: "#05C3F9",
              },
            },
          },
          {
            type: "map",
            map: "shenzhen",
            geoIndex: 0,
            aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: "#fff",
                },
              },
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: "#2B32B2",
                borderColor: "#2B32F2",
              },
              emphasis: {
                areaColor: "#2B91B7",
              },
            },
            animation: false,
            data: data,
          },
          {
            name: "点",
            type: "scatter",
            coordinateSystem: "geo",
            symbol: "pin",
            symbolSize: function (val) {
              var a = (maxSize4Pin - minSize4Pin) / (max - min);
              var b = minSize4Pin - a * min;
              b = maxSize4Pin - a * max;
              return a * val[2] + b;
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#fff",
                  fontSize: 9,
                },
              },
            },
            itemStyle: {
              normal: {
                color: "#F62157", //标志颜色
              },
            },
            zlevel: 6,
            data: convertData(data),
          },
          {
            name: "Top 5",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: convertData(
              data
                .sort(function (a, b) {
                  return b.value - a.value;
                })
                .slice(0, 5)
            ),
            symbolSize: function (val) {
              return val[2] / 10;
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "right",
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: "#fff200",
                shadowBlur: 20,
                shadowColor: "#fff201",
              },
            },
            zlevel: 1,
          },
        ],
      };
      this.initMap(option);
    },
  },
};
</script>

<style scoped>
.map-content {
  width: 100%;
  height: 100%;
}
.map-cx {
  display: flex;
  width: 100%;
  height: 100%;
}
.d-flex {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: aliceblue;
  align-content: center;
}
.fontS {
  font-size: 14px;
}
.left {
  flex: 0.31;
  z-index: 999;
  display: grid;
  grid-template-rows: 1fr 2fr;
}
.top {
  display: flex;
}
.guozai {
  flex: 1;
}
.zhongzai {
  flex: 1;
}

.circle {
  width: 40px; /* 你可以根据需要调整这个大小，但宽度和高度必须相同以保持圆形 */
  height: 40px; /* 同上 */
  margin-left: 20px;
  border: 5px solid gray; /* 边框样式：宽度为5px，颜色为灰色，实线 */
  border-radius: 50%; /* 圆角边框的半径设置为宽高的一半，以创建圆形 */
}
.red {
  background-color: red; /* 内部填充颜色 */
}
.redfont {
  color: red;
}
.yellowfont {
  color: yellow;
}
.yellow {
  background-color: yellow; /* 内部填充颜色 */
}
.bottom {
  padding-top: 50px;
}
.center {
  flex: 1.2;
}
.right {
  position: absolute;
  top: 10%;
  right: 32%;
}
::v-deep .el-select {
  width: 150px;
}
::v-deep .el-input__inner {
  background-color: rgba(0, 32, 136, 0.2);
  color: #fff;
}
::v-deep .el-select-dropdown__list {
  color: #fff;
}
::v-deep .el-select-dropdown__item.hover {
  background-color: rgba(0, 32, 136, 0.2);
}
::v-deep .el-select-dropdown__item:hover {
  background-color: rgba(0, 32, 136, 0.8);
}
</style>
<template>
  <div class="map-content">
    <div class="texts">深圳市</div>
    <div ref="orgOptions1" :style="{ width: '100%', height: '90%' }"></div>
  </div>
</template>

<script>
import { xian } from "@/api/index"
export default {
  data() {
    return {
    };
  },
  mounted() {
    this.setData();
  },
  methods: {
    initMap(option) {
      let that = this;
      if (that.myChart) {
        that.myChart.clear();
        that.myChart.dispose();
        that.myChart = null;
      }
      //   window.removeEventListener('resize', this.resizeHandler)
      that.myChart = that.$echarts.init(that.$refs.orgOptions1);
      let myChart = that.myChart;
        // let option;
      xian().then((svg) => {
        that.$echarts.registerMap("xian", svg);
        myChart.setOption(option, true);
        myChart.on("click", function (params) {
          // params.name 即为点击的地区名称
          let seriesData = that.seriesData.filter(
            (item) => item.name == params.name
          );
          // return
          // if (seriesData.length > 0) {
          //   that.$router.push({
          //     path: "/equipment",
          //     query: {
          //       stationName: seriesData[0].name,
          //       stationId: seriesData[0].id,
          //     },
          //   });
          //   sessionStorage.setItem("stationId", seriesData[0].id);
          // }
        });
      });
    },
    setData() {
      let option;
      let chartData = [
    {
      name: "福田区",
      latlong: [114, 22.35],
      value: Math.random() * 200,
    },
    {
      name: "罗湖区",
      latlong: [114.13, 22.55],
      value: Math.random() * 200,
    },
    {
      name: "宝安区",
      latlong: [113.89, 22.57],
      value: Math.random() * 200,
    },
    {
      name: "龙岗区",
      latlong: [114.30, 22.57],
      value: Math.random() * 200,
    }
  ];

  chartData = chartData.map((item) => {
    let a = [...item.latlong, item.value];
    return {
      name: item.name,
      value: item.value,
      scatter: [...item.latlong, item.value],
    };
  });
  debugger;

  option = {
    backgroundColor: "rgba(10,12,55,0.1)",

    tooltip: {
      trigger: "item",
      backgroundColor: "transparent",
      borderColor: "#FFFFCC",
      showDelay: 0,
      hideDelay: 0,
      enterable: true,
      transitionDuration: 0,
      extraCssText: "z-index:100",
      formatter: function (params, ticket, callback) {
        //根据业务自己拓展要显示的内容
        var res = "";
        var name = params.name;
        var value = params.count;
        res =
          "<span style='color:#fff;'>" + name + "</span><br/>数据：" + value;
        return res;
      },
    },
    legend: {
      orient: "vertical",
      top: "top",
      left: "right",
      data: ["22"],
      textStyle: {
        color: "#fff",
      },
    },
    geo: {
      map: "xian",
      itemStyle: {
        normal: {
          areaColor: "#24CFF4",
          borderColor: "#53D9FF",
          borderWidth: 1.3,
          shadowBlur: 15,
          shadowColor: "rgb(58,115,192)",
          shadowOffsetX: 7,
          shadowOffsetY: 6,
        },
        emphasis: {
          areaColor: "#8dd7fc",
          borderWidth: 1.6,
          shadowBlur: 25,
        },
      },
    },
    visualMap: {
      //min: 0,
      //max: 1,
      left: "3%",
      bottom: "5%",
      calculable: true,
      seriesIndex: [0],
      inRange: {
        color: ["#24CFF4", "#2E98CA", "#1E62AC"],
      },
      textStyle: {
        color: "#24CFF4",
      },
    },
    series: [
      {
        type: "map",
        geoIndex: 0,
        map: "xian",
        roam: true,
        zoom: 1.3,
        tooltip: {
          trigger: "item",
          formatter: (p) => {
            let val = p.value;
            if (p.name == "南海诸岛") return;
            if (window.isNaN(val)) {
              val = 0;
            }
            let txtCon =
              "<div style='text-align:left'>" +
              p.name +
              ":<br />过载量：" +
              val.toFixed(2) +
              "个</div>";
            return txtCon;
          },
        },
        label: {
          normal: {
            show: false,
          },
          emphasis: {
            show: false,
          },
        },
        data: chartData,
      },
      {
        name: "散点",
        type: "effectScatter",
        coordinateSystem: "geo",
        rippleEffect: {
          brushType: "fill",
        },
        itemStyle: {
          normal: {
            color: "#F4E925",
            shadowBlur: 20,
            shadowColor: "#333",
          },
        },
        data: chartData.map((it) => {
          return { name: it.name, value: it.scatter, count: it.value };
        }),
        symbolSize: function (val) {
          return val[2] / 10;
        },
        showEffectOn: "render", //加载完毕显示特效
      },
    ],
  };
      this.initMap(option)
    },
  },
};
</script>

<style scoped>
.map-content{
    width: 100%;
    height: 100%;
}
.texts{
  font-size: 20px;
  color: #3bffc4;
  position: relative;
  top: 10px;
}
</style>